.App {
  min-height: 100vh;
}

.Auth-Layout {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 24px;
}

.App-Header {
  display: flex;
  align-items: center;
  height: 60px;
  position: sticky;
  top: 0px;
  left: 0px;
  right: 0px;
  z-index: 900;
  padding: 0px 24px;
}

.App-Feedback {
  position: sticky;
  top: 80vh;
  z-index: 900;
}

.App-Body {
  min-height: 80vh;
  padding: 24px 0px;
}

@media screen and (max-width: 600px) {
  .App-Header {
    padding: 0px 12px;
  }
}
.footer_link{
    color: red;
}