$mobile-width: 480px;
$tablet-width: 768px;
$desktop-width: 1024px;
@mixin mobile {
  @media (max-width: #{$mobile-width - 1px}) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: #{$tablet-width}) and (max-width: #{$desktop-width - 1px}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$desktop-width}) {
    @content;
  }
}

.home {
  margin-top: 48;
  display: flex;
  justify-content: center;
  align-items: center;
  .ant-modal .modal-welcome {
    .level {
      color: red;
    }
  }
  .modal-welcome .ant-modal-body {
    border-radius: 20px 20px 0 0;
  }
  .ant-modal-content {
    background-color: green !important;
  }
  h1 {
    color: #b88914;
  }
  h3 {
    color: #42496e;
    margin: 0;
  }
  .description {
    word-wrap: break-word;
    color: #5e6484;
    font-size: 16px;
    margin: 20px 0px 42px 0px;
    text-align: center;
  }
  .button {
    width: 360px;
    height: 60px;
    background: linear-gradient(31.65deg, #DEB856 6.17%, #FDE29B 93.12%); 
    box-shadow: 0px 1px 30px rgba(9, 30, 66, 0.05), 0px 0px 1px rgba(9, 30, 66, 0.31);
    border-radius: 4px;
    cursor: pointer;
    color: #4d3700;
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .list-image{
    padding: 0px calc(50% - 730px);
  }
  img {

    @include mobile {
      width: 100%;
      height: 100%;
    }
    @include desktop() {
      object-fit: cover;
    }
  }
}

.rule-user {
  display: flex;
  background-color: rgb(244, 244, 247);
  flex-direction: column;
  align-items: center;
  .item {
    width: 134px;
  }
}
.App-Body {
    // background-color: #030708;
    padding: 0px !important;
    .home h1{
      color: #E0C685;
      margin-top: 30px;
    }
    .home h3{
      color: #fff;
      text-transform: uppercase;
      font-size: 32px;
    }
    .home .button{
      color: #17204D;
      text-transform: uppercase;
      margin-bottom: 50px;
      font-size: 16px;
    }
    .home .list-image img{
      width: 100%;
    }
}
@media (max-width: 1440px){
    .home .list-image{
      padding: 0px 10px;
    }
}