.thank-modal {
    width: 532px;
    height: 232px;
    background: #fff;
    text-align: center;
    border: 1px solid rgba(248, 244, 244, 0.877);
    img {
        margin-top: 40px;
    }
    .thankyou {
        margin-top: 30px;
        font-weight: 700;
        font-size: 25px;
        color: #242424;
    }
    .submission {
        font-size: 14px;
        margin-top: 15px;
        color: #585858;
    }
}

.wrap-thank {
    width: 532px;
    height: 232px;
    background: #fff;
    text-align: center;
    border: 1px solid #ece6e687;
    border-radius: 5px;
}