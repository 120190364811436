.modal-cu{
    position: fixed;
    height: 100vh;
    top: 0;
    z-index: 1000;
    left: 0;
    width: 100vw;
    background-size: 90%;
    background-repeat: no-repeat;
    padding: 50px 150px;
    background-position: center center;
    background-color: black;
}
