@import '~react-perfect-scrollbar/dist/css/styles.css';
@import 'styles/antd';
@import 'styles/layout';
@import 'styles/components';
@import 'styles/bootstrap';
@import 'styles/responsive';

body {
  margin: 0;
  font-family: 'Segoe UI Variable', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code,
pre,
input[type='code'],
textarea[type='code'] {
  font-family: 'Inconsolata', monospace !important;
}

* {
  outline: none !important;
}

a:hover {
  text-decoration: none !important;
}

@font-face {
  font-family: 'Segoe UI Variable';
  src: url('./assets/fonts/Segoe-UI-Variable-Static-Display-Bold.ttf') format('truetype');
  font-weight: 700;
}
@font-face {
  font-family: 'Segoe UI Variable';
  src: url('./assets/fonts/Segoe-UI-Variable-Static-Display-Semibold.ttf') format('truetype');
  font-weight: 600;
}
@font-face {
  font-family: 'Segoe UI Variable';
  src: url('./assets/fonts/Segoe-UI-Variable-Static-Display.ttf') format('truetype');
  font-weight: 400;
}