.wrap{
    .body{
        margin-top: 15px;
    }
    .warning-alert>div{
        width: 100%;
    }
}
.wrap-checkout {
    width: 600px;
    margin-bottom: 50px;
    border-radius: 4px;
    background: white;
    border: 1px solid #ccc;
    .header {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 24px;
        font-weight: 600;
        background: #EBECF0;
        padding: 15px 0;
    }
    .title-countdown {
        width: 100%;
        display: flex;
        justify-content: center;
        padding: 40px;
        flex-direction: column;
        align-items: center;
        .title {
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            color: #343C63;
        }
        .countdown {
            display: flex;
            font-size: 12px;
            line-height: 16px;
            color: #343C63;
            opacity: 0.6;
            .dot {
                margin: 0 10px;
                font-weight: 600;
                font-size: 24px;
            }
            .box {
                border: 1px solid rgba(179, 182, 197, 1);
                padding: 10px;
                border-radius: 8px;
                text-align: center;
                font-weight: 600;
                font-size: 24px;
            }
        }
    }
    .order-detail {
        padding: 0 40px 0px 40px;
        .order-detail-header {
            font-size: 14px;
            font-weight: 600;
        }
    }
    .desc {
        font-size: 14px;
        color: #091342;
        .note-box {
            padding: 24px;
            border-radius: 10px;
            background: #FFF0B3;
            .ul {
                padding-left: 24px;
            }
            .primary-key {
                font-size: 16px;
                color: #DE350B;
            }
        }
        padding: 40px;
    }
    .input-id {
        padding: 0 40px 40px 40px;
        .tran_id {
            width: 100%;
            height: 40px;
            background: #FAFAFC;
            border: 1px solid #C1C4D0;
            box-sizing: border-box;
            border-radius: 4px;
        }
    }
    .btn-submit {
        padding: 0 40px 40px 40px;
        .col-btn {
            display: flex;
            justify-content: flex-end;
            .btn-checkout {
                padding: 15px;
                color: #000;
                font-weight: 600;
                font-size: 16px;
                line-height: 20px;
                border-radius: 4px;
                background: linear-gradient(315.46deg, #D9A82A -0.13%, #EBDDB7 100%);
                cursor: pointer;
            }
        }
    }
}