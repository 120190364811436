html,body {
  height: 100%;
  background-color: #f1f1f3;
}
.containerBottom{
	background-color: #CFB675;
	color: #e3e3e3;
	padding: 30px calc(50% - 720px);
	width: 100%;
	position: relative;
	float: left;
	min-height: 120px;
	background-image: url(../images/footer-bg.png);
	background-position: left bottom;
    background-repeat: no-repeat;
    background-size: 7%;
}
.App-Header{
	width: 100%;
	padding: 0px 5px;
}

@media (min-width: 1340px){
	.App-Header{
		padding: 0 calc(50% - 670px) !important;
	}	
}
.launchpad-container{
	width:  100%;
	max-width: 1340px;
	margin: 0 auto;
}
.launchpad-container .banner{
	width: 100%;
	max-height: 400px;
	min-height: 389px;
	border-radius: 5px;
	display: flex;
	background-image: url(../images/bg-countdown.png);
	background-position: bottom center;
    background-size: cover;
    justify-content: center;
	flex-direction: column;
	align-items: center;
}
.launchpad-container .banner .count-down{
	width: 50%;
	text-align: center;
}
.launchpad-container .banner .count-down .text-welcome{
    position: relative;
    width: 100%;
    text-align: center;
    font-size: 26px;
    font-weight: 400;
    color: #FFFFFF;
}
.launchpad-container .banner .count-down .time-countdown{
	width: 60%;
	border-radius: 20px;
	border: 2px solid;	
	position: relative;
	margin: 0 auto;
	padding:  20px 40px;	
	background: linear-gradient(0deg, rgba(72, 20, 9, 0.3), rgba(72, 20, 9, 0.3)),rgba(49, 48, 54, 0.3);
	border-image-source: linear-gradient(165.1deg, #000000 -10.12%, rgba(255, 255, 255, 0.2) 119.7%);
}
.launchpad-container .banner .count-down .time-countdown > div{
	display: flex;
	flex-direction: row;
}
.launchpad-container .banner .count-down .time-countdown > div .box-time {
    display: inline-block;
    background-color: #E0C685;
    padding: 10px 15px;
    width: 59px;
    height: 51px;
    border-radius: 8px;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 31px;
    text-align: center;
    color: #242424;
    margin-bottom: 5px;
  }
.launchpad-container .banner .count-down .time-countdown > div .item-time {
	color: #fff;
	flex: 1;
	align-items: center;
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 16px;
	text-align: center;
	color: #ffffff;
}
.launchpad-container .banner .count-down .time-countdown > div .dot {
	color: #fff;
	font-size: 24px;
	padding-top: 6px;
}
.launchpad-container .banner .count-down .time-countdown .sub-title {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 2em;
    text-align: center;
    color: #ffffff;
    opacity: 0.6;
}
.launchpad-container .banner .count-down .text-login-vip{
	padding:  5px 10px;
	background:  white;
	display: inline-block;
	border-radius: 5px;
}
.launchpad-container .banner .count-down .tooltip-permision{
	font-size: 15px;
}
.launchpad-container .banner .count-down .tooltip-permision ul li{
	font-size: 15px;
}

.launchpad-container .banner .count-down .text-login-vip span{
	font-size: 20px;
	text-align: center;
}
.box-virtual .card-item {
	box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2), 0px 0px 1px rgba(9, 30, 66, 0.31);
	border-radius: 8px;
}
.box-virtual h1{
	font-size: 33px;
}
.box-virtual .card-item h2{
	font-size: 24px;
	text-transform: uppercase;
	font-weight: normal;
	line-height: 28px;
}
.box-virtual .time-left {
	color: #5e6484;
	font-weight: 400;
	font-size: 14px;
}
.box-virtual .infor .title {
	font-weight: bold;
	font-size: 16px;
}
.box-virtual .infor .description {
    color: #7a7f9a;
    font-size: 14px;
    line-height: 30px;
    font-weight: 400;
}
.box-virtual .box-price {
  display: flex;
  align-items: flex-end;
}
.box-virtual .box-price .price {
	color: #B88914;
	font-size: 20px;
	font-weight: 600;
	margin-left: 5px;
}
.box-virtual .box-image {
	background-color: #F1F1F3;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 8px;
}
.box-virtual .box-image .item {
	width: 216px;
	height: 310px;
}
.item-card-popup .ant-modal-content{
	background: transparent !important;
}
.add-to-cart-btn *{
	margin: 10px;
}
.btn-c{
	background: linear-gradient(
		315.46deg, #D9A82A -0.13%, #EBDDB7 100%) !important;
}
.add-to-cart-btn, .buy-now-btn{
	background: transparent;
	padding: 5px 40px;
	color: white;
	height: 48px;
	display: flex;
	align-items: center;
	text-transform: uppercase;
	min-width: 240px;
	text-align: center;
}
.add-to-cart-btn:hover{
	background: black;
}
.add-to-cart-btn, .buy-now-btn span{
	text-align: center;
	flex: 1;
	text-transform: uppercase;
}
.buy-now-btn span{
	color: black;
}
.warning-alert > div{
	margin-top: 20px;
    padding: 10px 20px;
    border-radius: 5px;
    background: yellow;
    color: black;
    background: #d6b81b;
}
.header-level{
	background-Color: red;
	color: white;
	padding: 0px 15px;
	font-weight: bold;
	font-size: 14px;
}
.header-level:hover{
	background-color: #cfb675;
}
.header-address{
	margin:  0 5px;
}

.footer-menu{
	display: block;
}
.footer-menu h2, .footer-contact h2{
	width: 100%;
	position: relative;
	float: left;
	font-size: 20px;
	line-height: 1.3em;
	color: #242424;
	font-weight: 600;
}
.footer-menu a{
	width: 100%;
	position: relative;
	float: left;
	color: #242424;
	font-size: 14px;
	line-height: 2em;
}
.footer-contact a{
	position: relative;
	float: left;
	color: #242424;
	font-size: 14px;
	line-height: 2em;
	padding-right: 10px;
}
.footer-contact a div{
	position: relative;
	float: left;
}
.footer-contact a img{
	max-height: 30px;
}
